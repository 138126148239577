import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { formatDateTime } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Button from 'uikit/Button/Button';
import './MarkupGroupsTableRowContent.scss';

export const MarkupGroupsTableRowContent = ({
  isLoading,
  markupGroupData,
  handleDeleteMarkupGroup,
  handleRenameMarkupGroup
}) => {
  const i18n = useContext(i18nContext);

  const getMarkupGroupInfoBlock = (markupGroupBlockInfo, isMarkupGroupId = false, isActionsRow = false) => {
    if (isLoading) {
      return (
        <Placeholder as={'p'} animation={'glow'}>
          <Placeholder className={'markup-groups-loader-placeholder'} xs={12} bg={'secondary'} />
        </Placeholder>
      );
    } else if (isMarkupGroupId) {
      return (
        <NavLink
          className={'markup-groups-title-link'}
          to={`${ROUTE_PATHS.MARKUPS_GROUPS}/${encodeURIComponent(markupGroupData.markupGroupId)}`}
        >
          {markupGroupBlockInfo}
        </NavLink>
      );
    } else if (isActionsRow) {
      return (
        <div className={'markup-groups-table-cell-actions'}>
          <Button
            onClick={() => handleRenameMarkupGroup(markupGroupData.markupGroupId)}
            type={'primary'}
            size={'small'}
          >
            {i18n.getMessage('markupGroups.table.rowContent.actions.rename')}
          </Button>
          <Button
            onClick={() => handleDeleteMarkupGroup(markupGroupData.markupGroupId)}
            type={'primary'}
            size={'small'}
          >
            {i18n.getMessage('markupGroups.table.rowContent.actions.delete')}
          </Button>
        </div>
      );
    } else {
      return <p>{markupGroupBlockInfo}</p>;
    }
  };

  return (
    <tr>
      <td className={'markup-groups-table-cell'}>{getMarkupGroupInfoBlock(markupGroupData.markupGroupId, true)}</td>
      <td className={'markup-groups-table-cell'}>
        {getMarkupGroupInfoBlock(formatDateTime(markupGroupData.createdAt))}
      </td>
      <td className={'markup-groups-table-cell'}>
        {getMarkupGroupInfoBlock(formatDateTime(markupGroupData.updatedAt))}
      </td>
      <td className={'markup-groups-table-cell'}>{getMarkupGroupInfoBlock(markupGroupData.customersCount)}</td>
      <td className={'markup-groups-table-cell'}>{getMarkupGroupInfoBlock(markupGroupData, false, true)}</td>
    </tr>
  );
};

MarkupGroupsTableRowContent.propTypes = {
  isLoading: PropTypes.bool,
  handleDeleteMarkupGroup: PropTypes.func,
  handleRenameMarkupGroup: PropTypes.func,
  markupGroupData: PropTypes.shape({
    markupGroupId: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    enabled: PropTypes.bool,
    customersCount: PropTypes.number
  })
};
